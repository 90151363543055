const SPACE_DATA = {
		"u6995544":  [{space_js: "//swutws.cdymxikj.com/site/up/o_x/production/x_mmll.js", space_src: "baidujs", space_type: "inters", space_id: "u6995544"}],
		"u6995545":  [{space_js: "//swutws.cdymxikj.com/source/vq/production/py/static/y_nn/common/mn.js", space_src: "baidujs", space_type: "native", space_id: "u6995545"}],
		"u6995546":  [{space_js: "//swutws.cdymxikj.com/source/w_rqzz_oon/q.js", space_src: "baidujs", space_type: "native", space_id: "u6995546"}],
		"u6995547":  [{space_js: "//swutws.cdymxikj.com/source/x/static/s_r/resource/aapp/production/ot.js", space_src: "baidujs", space_type: "native", space_id: "u6995547"}],
};
export const SPACE_MAP = {
	Home_inter:  SPACE_DATA["u6995544"],
	Home_banner:  SPACE_DATA["u6995545"],
	Home_native:  SPACE_DATA["u6995546"],
	Home_native_center1:  SPACE_DATA["u6995547"],
	Detail_inter:  SPACE_DATA["u6995544"],
	Detail_banner:  SPACE_DATA["u6995545"],
	Detail_native:  SPACE_DATA["u6995546"],
	Detail_native_center1:  SPACE_DATA["u6995547"],
	Result_inter:  SPACE_DATA["u6995544"],
	Result_banner:  SPACE_DATA["u6995545"],
	Result_native:  SPACE_DATA["u6995546"],
	Result_native_center1:  SPACE_DATA["u6995547"],
}
export const ALL_SPACE_MAP = {
    H5_YMAI_VO_4 : {
		Home_inter:  SPACE_DATA["u6995544"],
		Home_banner:  SPACE_DATA["u6995545"],
		Home_native:  SPACE_DATA["u6995546"],
		Home_native_center1:  SPACE_DATA["u6995547"],
		Detail_inter:  SPACE_DATA["u6995544"],
		Detail_banner:  SPACE_DATA["u6995545"],
		Detail_native:  SPACE_DATA["u6995546"],
		Detail_native_center1:  SPACE_DATA["u6995547"],
		Result_inter:  SPACE_DATA["u6995544"],
		Result_banner:  SPACE_DATA["u6995545"],
		Result_native:  SPACE_DATA["u6995546"],
		Result_native_center1:  SPACE_DATA["u6995547"],
    },
    DEFAULT : {
		Home_inter:  SPACE_DATA["u6995544"],
		Home_banner:  SPACE_DATA["u6995545"],
		Home_native:  SPACE_DATA["u6995546"],
		Home_native_center1:  SPACE_DATA["u6995547"],
		Detail_inter:  SPACE_DATA["u6995544"],
		Detail_banner:  SPACE_DATA["u6995545"],
		Detail_native:  SPACE_DATA["u6995546"],
		Detail_native_center1:  SPACE_DATA["u6995547"],
		Result_inter:  SPACE_DATA["u6995544"],
		Result_banner:  SPACE_DATA["u6995545"],
		Result_native:  SPACE_DATA["u6995546"],
		Result_native_center1:  SPACE_DATA["u6995547"],
    },
};
